// All the good stuff is in this directory
export type * from './types';

// This enum is a little unintuitive in that we're effectively combining several columns (status, graduated_with_honors, will_not_graduate_reason_id)
// on the ProgramInclusion model to come up with a single, user-friendly "graduation status."
export enum GraduationStatus {
    failed = 'failed',
    graduated = 'graduated',
    honors = 'honors',
    pending = 'pending',
}
