import { type ReactElement, useCallback, useEffect, useMemo } from 'react';
import { sortBy } from 'lodash/fp';
import { useFieldArray, useFormContext } from 'FrontRoyalReactHookForm';
import { type CohortSection, CohortSectionType } from 'Cohorts/CohortAttrs.types';
import { type CohortSectionOffer } from 'CohortSectionOffer';
import { type AnyObject } from '@Types';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCohorts } from '../redux';
import { ChangeScholarshipSelect, ChangeSectionSelect } from './index';
import { type SectionOfferFormValue } from './EditSectionOffers.types';

type EditSectionOffersProps = {
    cohortId: string;
    sectionOffers?: CohortSectionOffer[];
};

interface SectionAndScholarshipSelectProps {
    cohortSectionOfferIndex: number;
    sections: CohortSection[];
    availableScholarshipIds: string[];
    handleRemoveSection: (index: number) => void;
    sectionType: CohortSectionType;
}

const emptyCohortSectionTypeMap: Record<CohortSectionType, CohortSection[]> = {
    [CohortSectionType.Regional]: [],
    [CohortSectionType.Default]: [],
};

const sectionTypeLabelMap = {
    [CohortSectionType.Default]: 'Global',
    [CohortSectionType.Regional]: 'Regional',
} as const;

const SectionAndScholarshipSelect = ({
    cohortSectionOfferIndex,
    sections,
    availableScholarshipIds,
    handleRemoveSection,
    sectionType,
}: SectionAndScholarshipSelectProps) => {
    const { watch, setFieldValue } = useFormContext();

    const offeredScholarshipIdsContextProp = `cohortSectionOffers[${cohortSectionOfferIndex}].offeredScholarshipIds`;
    const offeredScholarshipIds: string[] = watch(offeredScholarshipIdsContextProp);

    const handleAddScholarship = useCallback(() => {
        setFieldValue(offeredScholarshipIdsContextProp, [...offeredScholarshipIds, undefined]);
    }, [offeredScholarshipIds, offeredScholarshipIdsContextProp, setFieldValue]);

    const handleRemoveScholarship = useCallback(
        (index: number) => {
            offeredScholarshipIds.splice(index, 1);
            setFieldValue(offeredScholarshipIdsContextProp, offeredScholarshipIds);
        },
        [offeredScholarshipIds, offeredScholarshipIdsContextProp, setFieldValue],
    );

    return (
        <div className="section-and-scholarship">
            <div>
                <p>{sectionTypeLabelMap[sectionType]} Section</p>
                <ChangeSectionSelect
                    name={`cohortSectionOffers[${cohortSectionOfferIndex}].cohortSectionId`}
                    sections={sections}
                />
            </div>
            <Button className="add-scholarship" startIcon={<AddIcon />} onClick={() => handleAddScholarship()}>
                Add Scholarship
            </Button>
            {offeredScholarshipIds.map((_id, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                    <p>Scholarship {index + 1}</p>
                    <ChangeScholarshipSelect
                        name={`${offeredScholarshipIdsContextProp}[${index}]`}
                        availableIds={availableScholarshipIds}
                        handleRemoveScholarship={() => handleRemoveScholarship(index)}
                    />
                </div>
            ))}
            {sectionType !== CohortSectionType.Default && (
                <div className="flex justify-end">
                    <button type="button" onClick={() => handleRemoveSection(cohortSectionOfferIndex)}>
                        Remove Section
                    </button>
                </div>
            )}
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
export const EditSectionOffers = ({ sectionOffers, cohortId }: EditSectionOffersProps): ReactElement | null => {
    const { control, watch } = useFormContext<{ cohortSectionOffers: SectionOfferFormValue[] }>();
    const formValues = watch('cohortSectionOffers', []);
    const { fields: inputFields, append, remove, replace } = useFieldArray({ control, name: 'cohortSectionOffers' });
    const { cohortsById } = useCohorts();

    const cohortSectionOffers = inputFields.map((input, index) => ({ ...input, ...formValues[index] }));

    const cohort = useMemo(() => cohortsById?.[cohortId], [cohortsById, cohortId]);

    const sectionTypeBySectionId = useMemo<AnyObject<CohortSectionType>>(
        () => cohort?.cohortSections?.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.sectionType }), {}) || {},
        [cohort],
    );

    const availableSectionOffers = useMemo(() => {
        if (!cohort) return [];
        let _availableSectionOffers;

        if (sectionOffers && sectionOffers.length > 0) {
            const availableSectionIds = cohort.cohortSections.map(section => section.id);
            _availableSectionOffers = sectionOffers
                .filter(cso => availableSectionIds.includes(cso.cohortSectionId))
                .map(offer => ({
                    cohortSectionId: offer.cohortSection.id,
                    offeredScholarshipIds: offer.offeredScholarshipIds,
                }));
        }

        const offers =
            _availableSectionOffers ||
            cohort.cohortSections.map(section => ({
                cohortSectionId: section.id,
                offeredScholarshipIds: [],
            }));

        return sortBy(
            offer => (sectionTypeBySectionId[offer.cohortSectionId] === CohortSectionType.Default ? '0' : '1'),
            offers,
        );
    }, [cohort, sectionOffers, sectionTypeBySectionId]);

    const cohortSectionsBySectionType = useMemo(
        () =>
            cohort?.cohortSections?.reduce(
                (prev, curr) => ({ ...prev, [curr.sectionType]: [...prev[curr.sectionType], curr] }),
                emptyCohortSectionTypeMap,
            ) || emptyCohortSectionTypeMap,

        [cohort],
    );

    useEffect(() => {
        replace(availableSectionOffers);
    }, [replace, availableSectionOffers]);

    const handleAddRegionalSection = useCallback(() => {
        const { id } = cohortSectionsBySectionType[CohortSectionType.Regional][0];
        const newCohortSectionOffer: SectionOfferFormValue = {
            offeredScholarshipIds: [],
            cohortSectionId: id,
        };
        append(newCohortSectionOffer);
    }, [append, cohortSectionsBySectionType]);

    const addSectionDisabled = useMemo(
        () =>
            !!cohortSectionOffers.find(
                val => sectionTypeBySectionId[val.cohortSectionId] === CohortSectionType.Regional,
            ) || !cohortSectionsBySectionType[CohortSectionType.Regional].length,
        [cohortSectionOffers, cohortSectionsBySectionType, sectionTypeBySectionId],
    );

    return cohort && cohortSectionOffers.length ? (
        <div className="change-section-or-scholarship">
            <div className="flex justify-end">
                <button type="button" onClick={handleAddRegionalSection} disabled={addSectionDisabled}>
                    Add Section
                </button>
            </div>
            {cohortSectionOffers.map(({ id, cohortSectionId }, index) => (
                <SectionAndScholarshipSelect
                    key={id}
                    cohortSectionOfferIndex={index}
                    availableScholarshipIds={cohort.availableScholarshipIds || []}
                    sections={cohortSectionsBySectionType[sectionTypeBySectionId[cohortSectionId]] || []}
                    sectionType={sectionTypeBySectionId[cohortSectionId]}
                    handleRemoveSection={remove}
                />
            ))}
        </div>
    ) : null;
};

export default EditSectionOffers;
