import { type ResourceLoader, type TimeoutId } from './SuspendableResource.types';

const DEFAULT_PING_INTERVAL = 60 * 1000;

// See comments in useSuspendableResource.ts for more information
export class SuspendableResource<ResultType> {
    status: 'uninitialized' | 'pending' | 'success' | 'error';
    result: ResultType | undefined;
    error: unknown;
    suspender: Promise<void> | undefined;
    resourceLoader: ResourceLoader<ResultType>;
    timeoutIds: TimeoutId[];
    eventCancelers: (() => void)[];
    pingInterval: number;
    constructor(
        resourceLoader: ResourceLoader<ResultType>,
        { pingInterval = DEFAULT_PING_INTERVAL }: { pingInterval?: number } = {},
    ) {
        this.resourceLoader = resourceLoader;
        this.timeoutIds = [];
        this.eventCancelers = [];
        this.status = 'uninitialized';
        this.pingInterval = pingInterval;
    }

    read() {
        if (this.status === 'uninitialized') {
            this.suspender = this.triggerResourceLoader();
            this.status = 'pending';
        }

        if (this.status === 'pending') {
            throw this.suspender; // Suspend rendering until the promise resolves
        } else if (this.status === 'error') {
            throw this.error; // Throw the error if the fetch failed
        }

        return this.result!; // Return the result if successful. Given the status, we know that ths result is defined by now
    }

    load() {
        const promise = this.triggerResourceLoader();

        return {
            result: this.read(),
            promise,
        };
    }

    // This function reads the current value, schedules a reload for 1 minute from now,
    // and returns an object with:
    // * the current value
    // * a promise that resolves when the value is reloaded
    // * a function to cancel the reload
    ping() {
        let timeoutId: TimeoutId;
        let cancelEventListener: () => void;

        function cancelPing() {
            clearTimeout(timeoutId);
            cancelEventListener();
        }

        const promise: Promise<void> = new Promise(resolve => {
            const reload = () => {
                cancelPing();
                return this.triggerResourceLoader().then(resolve);
            };
            window.addEventListener('focus', reload);
            cancelEventListener = () => window.removeEventListener('focus', reload);
            this.eventCancelers.push(cancelEventListener);

            timeoutId = setTimeout(reload, this.pingInterval);
            this.timeoutIds.push(timeoutId);
        });

        return {
            result: this.read(),
            promise,
            cancelPing: () => {
                clearTimeout?.(timeoutId);
                cancelEventListener?.();
            },
        };
    }

    destroy() {
        this.timeoutIds.forEach(clearTimeout);
        this.eventCancelers.forEach(cancel => cancel());
    }

    private triggerResourceLoader() {
        return this.resourceLoader().then(
            res => {
                this.status = 'success';
                // It's probably bad that ensureStudentDashboard() returns an object that gets mutated when progress
                // changes, but it does and I was afraid to touch it. Since I know that this function is only used in
                // new code, I'm comfortable having it return a new object each time we load.
                this.result = { ...res };
            },
            err => {
                this.status = 'error';
                this.error = err;
            },
        );
    }
}
