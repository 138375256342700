import { useEffect } from 'react';
import * as Yup from 'yup';
import { type Nullable } from '@Types';
import { Checkbox, DatePicker, Select } from 'FrontRoyalMaterialUiForm';
import { type ProgramInclusion, HumanReadableAcademicProbationStatus } from 'ProgramInclusion';
import { useFormContext } from 'FrontRoyalReactHookForm';
import moment from 'moment';
import { type Moment } from 'moment-timezone';
import { InclusionActionType, UserManagementActionNamespace, type FormFieldProps } from '../UserManagementAction.types';
import { useCohorts, useGetUserInAdmin } from '../../redux';
import AbstractUserManagementAction from '../AbstractUserManagementAction';

const NOT_ON_ACADEMIC_PROBATION_VALUE = '';
const NOT_ON_ACADEMIC_PROBATION_LABEL = 'Not on Academic Probation';

type AcademicProbationStatusFormFieldValue =
    | keyof typeof HumanReadableAcademicProbationStatus
    | typeof NOT_ON_ACADEMIC_PROBATION_VALUE;

type FormFields = {
    extendExamTime: boolean;
    disableExamLocking: boolean;
    honorsIneligible: boolean;
    graduatedWithHonors: boolean;
    graduationExtensionDate: Moment | null;
    grantGraduationExtension: boolean;
    requiresTilaDisclosure: boolean;
    academicHold: boolean;
    academicProbationStatus: AcademicProbationStatusFormFieldValue;
};

type ExecuteActionParams = Omit<
    FormFields,
    'academicProbationStatus' | 'graduationExtensionDate' | 'grantGraduationExtension'
> & {
    academicProbationStatus: Nullable<keyof typeof HumanReadableAcademicProbationStatus>;
    graduationExtensionEndsAt: Nullable<number>;
};

type AcademicProbationStatusOption = {
    value: AcademicProbationStatusFormFieldValue;
    label: HumanReadableAcademicProbationStatus | typeof NOT_ON_ACADEMIC_PROBATION_LABEL;
};

const academicProbationStatusOptions: AcademicProbationStatusOption[] = Object.entries(
    HumanReadableAcademicProbationStatus,
).map(entry => ({
    value: entry[0] as keyof typeof HumanReadableAcademicProbationStatus,
    label: entry[1],
}));
academicProbationStatusOptions.splice(0, 0, {
    value: NOT_ON_ACADEMIC_PROBATION_VALUE,
    label: NOT_ON_ACADEMIC_PROBATION_LABEL,
});

class ChangeSettings extends AbstractUserManagementAction {
    static actionType = InclusionActionType.ChangeSettings;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    static description = (<p>Use to change the student&apos;s inclusion settings.</p>);

    static formValidationSchema = Yup.object().shape({
        extendExamTime: Yup.boolean().required(),
        disableExamLocking: Yup.boolean().required(),
        graduationExtensionDate: Yup.date().nullable().defined(),
        honorsIneligible: Yup.boolean().required(),
        requiresTilaDisclosure: Yup.boolean().required(),
        academicHold: Yup.boolean().required(),
        academicProbationStatus: Yup.string().nullable(),
    });

    formatFormValues(values: FormFields): ExecuteActionParams {
        return {
            ...values,
            academicProbationStatus: values.academicProbationStatus || null,
            graduationExtensionEndsAt: values.graduationExtensionDate
                ? values.graduationExtensionDate.valueOf() / 1000
                : null,
        };
    }

    // eslint-disable-next-line max-lines-per-function
    static FormFields = ({ record, userId }: FormFieldProps<ProgramInclusion>) => {
        const { formState, reset, watch, setFieldValue } = useFormContext<FormFields>();
        const { user } = useGetUserInAdmin(userId);
        const {
            cohortId,
            disableExamLocking,
            honorsIneligible,
            graduatedWithHonors,
            graduationExtensionEndsAt,
            status,
            requiresTilaDisclosure,
            auditing,
            academicHold,
            academicProbationStatus,
        } = record;
        const notGraduated = status !== 'graduated';

        useEffect(() => {
            reset({
                extendExamTime: !!user?.extendExamTime,
                disableExamLocking,
                honorsIneligible,
                requiresTilaDisclosure,
                graduatedWithHonors,
                graduationExtensionDate:
                    (graduationExtensionEndsAt && moment(graduationExtensionEndsAt * 1000)) || null,
                grantGraduationExtension: !!graduationExtensionEndsAt,
                academicHold,
                academicProbationStatus: academicProbationStatus || NOT_ON_ACADEMIC_PROBATION_VALUE,
            });
        }, [
            reset,
            user?.extendExamTime,
            disableExamLocking,
            honorsIneligible,
            requiresTilaDisclosure,
            graduatedWithHonors,
            graduationExtensionEndsAt,
            academicHold,
            academicProbationStatus,
        ]);

        const graduationExtensionDate = watch('graduationExtensionDate');
        const grantGraduationExtension = watch('grantGraduationExtension');

        const { cohortsById } = useCohorts();
        const cohort = cohortsById[cohortId];
        const cohortGraduationDate = cohort.graduationDate;

        // grantGraduationExtension is just a dummy checkbox controlling whether to display the date picker field
        // for graduationExtensionDate.  We respond to changes here by setting or unsetting the date.
        useEffect(() => {
            if (grantGraduationExtension && !graduationExtensionDate) {
                // default to 1 week after the Cohort's graduation date
                setFieldValue(
                    'graduationExtensionDate',
                    moment(cohortGraduationDate ? cohortGraduationDate * 1000 : new Date())
                        .add(7, 'd')
                        .tz('America/New_York')
                        .endOf('day'),
                );
            } else if (!grantGraduationExtension && graduationExtensionDate) {
                setFieldValue('graduationExtensionDate', null);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [cohortGraduationDate, grantGraduationExtension, setFieldValue]);

        return notGraduated ? (
            <>
                <Checkbox name="extendExamTime" label="Extend exam time" />
                <Checkbox name="disableExamLocking" label="Disable exam locking" />
                <Checkbox name="honorsIneligible" label="Ineligible for honors" />
                <Checkbox name="grantGraduationExtension" label="Grant graduation extension" />
                {graduationExtensionDate && (
                    <DatePicker
                        minDate={moment()}
                        // maxDate=
                        // No need for hour/minute precision here.
                        views={['year', 'month', 'day']}
                        timezone="America/New_York"
                        name="graduationExtensionDate"
                        label="Graduation Extension"
                        className="date-picker-input"
                        disabled={formState.isSubmitting}
                    />
                )}
                {!auditing && <Checkbox name="requiresTilaDisclosure" label="Require TILA disclosure" />}
                <Checkbox name="academicHold" label="On administrative hold" />
                <Select
                    name="academicProbationStatus"
                    label="Academic Probation Status"
                    fullWidth
                    disabled={formState.isSubmitting}
                    options={academicProbationStatusOptions}
                />
            </>
        ) : (
            <>
                {academicHold && <Checkbox name="academicHold" label="On administrative hold" />}
                <Checkbox name="graduatedWithHonors" label="Graduated with honors" />
            </>
        );
    };
}

export default ChangeSettings;
