import { useEffect, useState } from 'react';
import { type SuspendableResource } from './SuspendableResource';

/*
    A SuspendableResource is a singleton that is responsible for managing polling for cached resource in a way that
    Suspense can deal with. We use this to wrap resources that we have built in the past. Going forward, we should be
    using RTKQuery to get similar functionality.

    In order to make our resources suspense-enabled, we need to set up a way to trigger an asynchronous fetch, trigger
    suspense, and then later have synchronous access to the result. See https://www.bbss.dev/posts/react-learn-suspense/.
    The `read` method on SuspendableResource does this for us by

    * Returning the result if it's already loaded
    * Throwing a promise that will suspend if it's not loaded
    * Throwing an error if the fetch failed

    In all the places where we use SuspendableResource, the underlying resource (LearnerContentCache or UserProgressLoader)
    is responsible for knowing when it's data is out of date and needs to be re-fetched. But, since the SuspendableResource
    has to keep its own synchronously-accessible pointer to the result, it's possible for the SuspendableResource to have an out-of-date
    result after the underlying resource has reloaded.

    So, what we want to do is:
    * Whenever we have a new component using useSuspendableResource, it should be sure to get the most recent
        data from the underlying resource.
    * Every so often as long as that component stays mounted, it should poll for updates to the data from the
        underlying resource.

    Here's how we deal with this:

    Case 1: The component mounts and the underlying resource has not yet loaded data.
    1. On the initial render, isInitialLoad is true, so we call `load` on the SuspendableResource,
        which triggers a fetch of the data and throws a promise, triggering suspense.
    2. Once the promise returned by load resolves, suspense re-mounts the component.
    3. isInitialLoad is true because this is once again the initial mount of a new component, so we call `load` again.
        This synchronously returns the cached result, but it also triggers a second call to the resourceLoader.  At this
        point, we would prefer not to trigger that second call, but since Suspense has unmounted the old component
        and remounted a new one, we don't have any way to detect that we've already done that. In any case, it doesn't
        matter, because the resourceLoader is responsible for knowing that it doesn't actually need to refetch anything
        right now. (For example, multiple calls to LearnerContentCache.ensureStudentDashboard will not trigger multiple fetches).
    4. The promise from the resourceLoader immediately resolves, triggering an increment of the dummyCounter and a re-render of the component
    5. Now, `isInitialLoad` is false, so we call suspendableResource.ping(). This returns the current result and a promise.
    6. After 1 minute (or if the window loses and regains focus), the SuspendableResource will trigger the resourceLoader again.
        If the underlying data has changed by then, then the result in the SuspendableResource will be updated. At that point,
        the promise will resolve, incrementing the dummyCounter and forcing the component to re-render with the new data.
    7. Go back to #5 and repeat as long as the component remains mounted.

    Case 2: The component mounts and the underlying resource has already loaded data.
    1. On the initial render, isInitialLoad is true, so we call `load` on the SuspendableResource. `load` synchronously
        returns the currently cached result, but it also triggers a call to the resourceLoader, checking to
        see if the currently cached result is out of date. We are now in the same situation as step 3 above. The only
        difference is that it's possible that the call to resourceLoader will return updated data.
    2. From here on out, it's the same as step 4 to the end above.
*/
export function useSuspendableResource<ResultType>(suspendableResource: SuspendableResource<ResultType>) {
    const [dummyCounter, setDummyCounter] = useState(0);
    const isInitialLoad = dummyCounter === 0;

    const { result, promise, cancelPing } = isInitialLoad
        ? { ...suspendableResource.load(), cancelPing: undefined }
        : suspendableResource.ping();

    useEffect(() => {
        promise.then(() => {
            setDummyCounter(c => c + 1);
        });
        return cancelPing;
    }, [promise, cancelPing, setDummyCounter]);

    return result;
}
