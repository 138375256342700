import { useCallback, useEffect } from 'react';

type UseTriggerCallbackOnIntervalProps<T> = {
    listToTrigger: T[] | readonly T[];
    callback: (item: T, isLastTrigger: boolean) => void | (() => void);
    intervalMs: number;
    delayOnStart?: boolean;
};

export const useTriggerCallbackOnInterval = <T>({
    listToTrigger,
    callback,
    intervalMs,
    delayOnStart = false,
}: UseTriggerCallbackOnIntervalProps<T>) => {
    const recursiveCallback = useCallback(
        (index: number) => {
            if (index >= listToTrigger.length) return undefined;

            const nextIndex = index + 1;
            const isLastTrigger = nextIndex >= listToTrigger.length;
            callback(listToTrigger[index], isLastTrigger);

            const timeoutId = setTimeout(() => recursiveCallback(nextIndex), intervalMs);

            return timeoutId;
        },
        [callback, intervalMs, listToTrigger],
    );

    useEffect(() => {
        const timeoutId = recursiveCallback(delayOnStart ? intervalMs : 0);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [recursiveCallback, delayOnStart, intervalMs]);
};
