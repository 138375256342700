import { Alert } from '@mui/material';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import {
    type UserManagementActionAttrs,
    InclusionActionType,
    UserManagementActionNamespace,
} from '../UserManagementAction.types';

interface GenerateTilaDisclosureAttrs extends UserManagementActionAttrs {
    hasSignedDocument: boolean;
}

class GenerateTilaDisclosure extends AbstractUserManagementAction {
    static actionType = InclusionActionType.GenerateTilaDisclosure;
    static namespace = UserManagementActionNamespace.ProgramInclusion;

    hasSignedDocument: boolean;

    constructor(attrs: GenerateTilaDisclosureAttrs) {
        super(attrs);
        this.hasSignedDocument = attrs.hasSignedDocument;
    }

    // Override to ensure TILA is all caps
    get title() {
        return 'Generate TILA Disclosure';
    }

    get description() {
        return (
            <div>
                <p>Generates a new TILA disclosure for the student.</p>
                <p>
                    Use this if you need to update the TILA disclosure due to changes in the student&apos;s registration
                    or if the previous disclosure was generated incorrectly.
                </p>
                {this.hasSignedDocument && (
                    <Alert
                        severity="warning"
                        sx={{
                            marginY: 2,
                            '& .MuiAlert-message': {
                                '& span': {
                                    color: 'error.main',
                                    fontWeight: 'bold',
                                },
                            },
                            backgroundColor: 'rgba(255, 244, 229, 1)',
                        }}
                    >
                        <span>Warning:</span> This student has already signed their TILA disclosure. Generating a new
                        one will require them to sign again.
                    </Alert>
                )}
            </div>
        );
    }
}

export default GenerateTilaDisclosure;
