import { useEffect } from 'react';
import { useFormContext } from 'FrontRoyalReactHookForm';
import * as Yup from 'yup';
import moment from 'moment';
import { isFullScholarship } from 'Scholarship';
import { isOneTimePlan } from 'TuitionPlan';
import { type AdmissionOffer, getGlobalCohortSectionOffer, showFastTrackRegistration } from 'AdmissionOffer';
import { Select } from 'FrontRoyalMaterialUiForm';
import { type BaseUser, getCohort } from 'Users';
import { formatNameAndStartDate } from 'Cohorts';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useCohorts, useGetUserInAdmin } from '../../redux';
import usePaymentSituation from '../../utils/usePaymentSituation';
import { ProgramPaymentCard } from '../../HelperComponents';
import TuitionBenefitAdministratorSelect from '../../HelperComponents/TuitionBenefitAdministratorSelect';
import TuitionPlanSelect from '../../HelperComponents/TuitionPlanSelect';
import AbstractUserManagementAction from '../AbstractUserManagementAction';
import PaymentSelect from '../../HelperComponents/PaymentSelect';
import { type HandlePaymentOptionVal } from '../../HelperComponents/PaymentSelect.types';
import { showDatePicker } from '../../HelperComponents/PaymentSelectHelper';
import { type FormFieldProps, OfferActionType, UserManagementActionNamespace } from '../UserManagementAction.types';
import { type FormFields, type ExecuteActionParams } from './RegisterStudent.types';
import {
    usePaymentOptions,
    useSetDefaultPaymentOption,
    useSetDefaultTuitionPlanId,
    useTuitionPlans,
} from './RegisterStudentHelpers';

const formValueKeys = ['handlePaymentOption', 'tuitionPlanId', 'registerForPreviousCohort'] as const;

class RegisterStudent extends AbstractUserManagementAction {
    static actionType = OfferActionType.RegisterStudent;
    static namespace = UserManagementActionNamespace.AdmissionOffer;

    static description = (
        <p>
            Use to register a student who has an admission offer. This is generally used when a student plans to make a
            payment outside of the normal flow in the app.
        </p>
    );

    static formValidationSchema = Yup.object().shape({
        tuitionPlanId: Yup.string().required(),
        tuitionBenefitAdministrator: Yup.string().nullable(),
        paymentGracePeriodEndAt: Yup.number().when('handlePaymentOption', {
            is: (val: HandlePaymentOptionVal) => showDatePicker(val) === true,
            then: Yup.number().required(),
            otherwise: Yup.number().nullable().defined(),
        }),
        registerForPreviousCohort: Yup.boolean(),
    });

    formatFormValues(values: FormFields): ExecuteActionParams {
        const { tuitionPlanId, paymentGracePeriodEndAt, tuitionBenefitAdministrator, registerForPreviousCohort } =
            values;

        return {
            paymentGracePeriodEndAt: paymentGracePeriodEndAt
                ? moment(paymentGracePeriodEndAt).valueOf() / 1000
                : paymentGracePeriodEndAt === null
                ? null
                : undefined,
            tuitionPlanId,
            tuitionBenefitAdministrator: tuitionBenefitAdministrator || null,
            registerForPreviousCohort: registerForPreviousCohort === 'true',
        };
    }

    // eslint-disable-next-line max-lines-per-function
    static FormFields = ({ userId, record }: FormFieldProps<AdmissionOffer>) => {
        const { user, userLoading } = useGetUserInAdmin(userId);
        const { cohortsById } = useCohorts();
        const {
            formState: { isSubmitting },
            watch,
            setFieldValue,
        } = useFormContext<FormFields>();
        const [handlePaymentOption, tuitionPlanId, registerForPreviousCohort] = watch(formValueKeys);
        const selectablePaymentSituations = record.selectablePaymentSituations;
        const cohortId = record.cohortId;
        const cohortSectionOffer = getGlobalCohortSectionOffer(record);
        const tuitionPlans = useTuitionPlans(
            selectablePaymentSituations,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );
        const handlePaymentOptions = usePaymentOptions(cohortSectionOffer);
        const isFullScholarshipSelected = isFullScholarship(cohortSectionOffer?.cumulativeOfferedScholarship);
        const isOneTimePlanSelected = isOneTimePlan(tuitionPlanId, tuitionPlans);

        useSetDefaultTuitionPlanId(tuitionPlans, tuitionPlanId);
        useSetDefaultPaymentOption(handlePaymentOptions, handlePaymentOption);

        const paymentSituation = usePaymentSituation(
            selectablePaymentSituations,
            tuitionPlanId,
            cohortSectionOffer?.cumulativeOfferedScholarship,
        );

        const cohort = getCohort(user as BaseUser, { programType: record.programType })!;
        useEffect(() => {
            if (showFastTrackRegistration(record, cohort) && !registerForPreviousCohort) {
                setFieldValue('registerForPreviousCohort', 'true');
            }
        }, [setFieldValue, registerForPreviousCohort, record, cohort]);

        return userLoading ? (
            <FrontRoyalSpinner />
        ) : (
            <>
                <TuitionPlanSelect
                    name="tuitionPlanId"
                    tuitionPlans={tuitionPlans}
                    disabled={isSubmitting || tuitionPlans.length === 0}
                />
                {cohortId && paymentSituation && !isFullScholarshipSelected && (
                    <ProgramPaymentCard
                        title="Payment Information"
                        paymentSituation={paymentSituation}
                        cohortId={cohortId}
                    />
                )}
                <TuitionBenefitAdministratorSelect name="tuitionBenefitAdministrator" disabled={isSubmitting} />
                <PaymentSelect
                    disabled={!paymentSituation || isFullScholarshipSelected}
                    isFullScholarship={isFullScholarshipSelected}
                    isOneTimePlan={isOneTimePlanSelected}
                    actionType={OfferActionType.RegisterStudent}
                />
                {showFastTrackRegistration(record, cohort) && (
                    <Select
                        name="registerForPreviousCohort"
                        label="Cohort"
                        fullWidth
                        disabled={isSubmitting}
                        options={[cohortsById[record.fastTrackCohortId!], cohortsById[record.cohortId]]}
                        optionLabel={c => formatNameAndStartDate(c)}
                        optionValue={c => (c.id === record.fastTrackCohortId ? 'true' : 'false')}
                    />
                )}
            </>
        );
    };
}

export default RegisterStudent;
