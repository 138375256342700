import type AbstractUserManagementAction from './AbstractUserManagementAction';
import {
    InclusionActionType,
    ApplicationActionType,
    OfferActionType,
    UserManagementActionNamespace,
    type UserManagementActionAttrs,
    type ActionAttrsWithServerData,
    UserActionType,
} from './UserManagementAction.types';

/* ProgramApplication */
import ApplicationRejectApplication from './ProgramApplication/RejectApplication';
import ApplicationUnrejectApplication from './ProgramApplication/UnrejectApplication';
import ApplicationChangeProgram from './ProgramApplication/ChangeProgram';
import ApplicationChangeSettings from './ProgramApplication/ChangeSettings';
import ApplicationConsiderInDifferentCohort from './ProgramApplication/ConsiderInDifferentCohort';
import DeleteApplication from './ProgramApplication/DeleteApplication';
import OfferAdmission from './ProgramApplication/OfferAdmission';
import OfferAdmissionInNewProgram from './ProgramApplication/OfferAdmissionInNewProgram';
import ProgramApplicationOfferAdmissionAfterDeclinedOffer from './ProgramApplication/OfferAdmissionAfterDeclinedOffer';
/* AdmissionOffer */
import ChangeOfferDetails from './AdmissionOffer/ChangeOfferDetails';
import RegisterStudent from './AdmissionOffer/RegisterStudent';
import ExpireOffer from './AdmissionOffer/ExpireOffer';
import DeleteOffer from './AdmissionOffer/DeleteOffer';
import ReactivateOffer from './AdmissionOffer/ReactivateOffer';
import AdmissionOfferOfferAdmissionAfterDeclinedOffer from './AdmissionOffer/OfferAdmissionAfterDeclinedOffer';
import SwitchOfferedProgram from './AdmissionOffer/SwitchOfferedProgram';
/* ProgramInclusion */
import InclusionChangeCohort from './ProgramInclusion/ChangeCohort';
import InclusionChangeProgram from './ProgramInclusion/ChangeProgram';
import IssueRefund from './ProgramInclusion/IssueRefund';
import ExpelOrWithdraw from './ProgramInclusion/ExpelOrWithdraw';
import Graduate from './ProgramInclusion/Graduate';
import ReadmitExpelled from './ProgramInclusion/ReadmitExpelled';
import ChangeRegistration from './ProgramInclusion/ChangeRegistration';
import ChangeScholarshipAfterRegistration from './ProgramInclusion/ChangeScholarshipAfterRegistration';
import ChangeSettings from './ProgramInclusion/ChangeSettings';
import GenerateDeferralLink from './ProgramInclusion/GenerateDeferralLink';
import MarkAsAuditing from './ProgramInclusion/MarkAsAuditing';
import UpdateBillingSituation from './ProgramInclusion/UpdateBillingSituation';
import GenerateEnrollmentAgreement from './ProgramInclusion/GenerateEnrollmentAgreement';
import GenerateTilaDisclosure from './ProgramInclusion/GenerateTilaDisclosure';
/* User */
import DeleteUser from './UserActions/DeleteUser';
import CancelDeletion from './UserActions/CancelDeletion';
import EditUserDetails from './UserActions/EditUserDetails';
import IncludeInProgram from './UserActions/IncludeInProgram';
import UpdateExecEdEligibilityBundle from './UserActions/UpdateExecEdEligibilityBundle';
import AllowReapplication from './UserActions/AllowReapplication';
import RegisterWithCorporateSponsor from './UserActions/RegisterWithCorporateSponsor';

type ActionFactory<T> = (attrs: T) => AbstractUserManagementAction;

type AttrsType<T extends typeof AbstractUserManagementAction = typeof AbstractUserManagementAction> =
    ConstructorParameters<T>[0];

const applicationActionFactories = {
    [ApplicationActionType.ChangeProgram]: (attrs: AttrsType<typeof ApplicationChangeProgram>) =>
        new ApplicationChangeProgram(attrs),
    [ApplicationActionType.ChangeSettings]: (attrs: AttrsType<typeof ApplicationChangeSettings>) =>
        new ApplicationChangeSettings(attrs),
    [ApplicationActionType.ConsiderInDifferentCohort]: (
        attrs: AttrsType<typeof ApplicationConsiderInDifferentCohort>,
    ) => new ApplicationConsiderInDifferentCohort(attrs),
    [ApplicationActionType.RejectApplication]: (attrs: UserManagementActionAttrs) =>
        new ApplicationRejectApplication(attrs),
    [ApplicationActionType.UnrejectApplication]: (attrs: UserManagementActionAttrs) =>
        new ApplicationUnrejectApplication(attrs),
    [ApplicationActionType.OfferAdmission]: (attrs: UserManagementActionAttrs) => new OfferAdmission(attrs),
    [ApplicationActionType.OfferAdmissionInNewProgram]: (attrs: AttrsType<typeof OfferAdmissionInNewProgram>) =>
        new OfferAdmissionInNewProgram(attrs),
    [ApplicationActionType.DeleteApplication]: (attrs: UserManagementActionAttrs) => new DeleteApplication(attrs),
    [ApplicationActionType.OfferAdmissionAfterDeclinedOffer]: (
        attrs: AttrsType<typeof ProgramApplicationOfferAdmissionAfterDeclinedOffer>,
    ) => new ProgramApplicationOfferAdmissionAfterDeclinedOffer(attrs),
};

const offerActionFactories = {
    [OfferActionType.ChangeOfferDetails]: (attrs: AttrsType<typeof ChangeOfferDetails>) =>
        new ChangeOfferDetails(attrs),
    [OfferActionType.RegisterStudent]: (attrs: UserManagementActionAttrs) => new RegisterStudent(attrs),
    [OfferActionType.ReactivateDeclinedOffer]: (attrs: UserManagementActionAttrs) => new ReactivateOffer(attrs),
    [OfferActionType.ExpireOffer]: (attrs: UserManagementActionAttrs) => new ExpireOffer(attrs),
    [OfferActionType.DeleteOffer]: (attrs: UserManagementActionAttrs) => new DeleteOffer(attrs),
    [OfferActionType.OfferAdmissionAfterDeclinedOffer]: (
        attrs: AttrsType<typeof AdmissionOfferOfferAdmissionAfterDeclinedOffer>,
    ) => new AdmissionOfferOfferAdmissionAfterDeclinedOffer(attrs),
    [OfferActionType.SwitchOfferedProgram]: (attrs: AttrsType<typeof SwitchOfferedProgram>) =>
        new SwitchOfferedProgram(attrs),
};

const inclusionActionFactories = {
    [InclusionActionType.ChangeCohort]: (attrs: AttrsType<typeof InclusionChangeCohort>) =>
        new InclusionChangeCohort(attrs),
    [InclusionActionType.ChangeProgram]: (attrs: AttrsType<typeof InclusionChangeProgram>) =>
        new InclusionChangeProgram(attrs),
    [InclusionActionType.Graduate]: (attrs: UserManagementActionAttrs) => new Graduate(attrs),
    [InclusionActionType.ExpelOrWithdraw]: (attrs: AttrsType<typeof ExpelOrWithdraw>) => new ExpelOrWithdraw(attrs),
    [InclusionActionType.ChangeSettings]: (attrs: UserManagementActionAttrs) => new ChangeSettings(attrs),
    [InclusionActionType.ReadmitExpelled]: (attrs: AttrsType<typeof ReadmitExpelled>) => new ReadmitExpelled(attrs),
    [InclusionActionType.MarkAsAuditing]: (attrs: AttrsType<typeof MarkAsAuditing>) => new MarkAsAuditing(attrs),
    [InclusionActionType.UpdateBillingSituation]: (attrs: AttrsType<typeof UpdateBillingSituation>) =>
        new UpdateBillingSituation(attrs),
    [InclusionActionType.GenerateDeferralLink]: (attrs: AttrsType<typeof GenerateDeferralLink>) =>
        new GenerateDeferralLink(attrs),
    [InclusionActionType.IssueRefund]: (attrs: AttrsType<typeof IssueRefund>) => new IssueRefund(attrs),
    [InclusionActionType.ChangeRegistration]: (attrs: AttrsType<typeof ChangeRegistration>) =>
        new ChangeRegistration(attrs),
    [InclusionActionType.ChangeScholarshipAfterRegistration]: (
        attrs: AttrsType<typeof ChangeScholarshipAfterRegistration>,
    ) => new ChangeScholarshipAfterRegistration(attrs),
    [InclusionActionType.GenerateEnrollmentAgreement]: (attrs: AttrsType<typeof GenerateEnrollmentAgreement>) =>
        new GenerateEnrollmentAgreement(attrs),
    [InclusionActionType.GenerateTilaDisclosure]: (attrs: AttrsType<typeof GenerateTilaDisclosure>) =>
        new GenerateTilaDisclosure(attrs),
};

const userActionFactories = {
    [UserActionType.DeleteUser]: (attrs: AttrsType<typeof DeleteUser>) => new DeleteUser(attrs),
    [UserActionType.CancelDeletion]: (attrs: UserManagementActionAttrs) => new CancelDeletion(attrs),
    [UserActionType.EditUserDetails]: (attrs: AttrsType<typeof EditUserDetails>) => new EditUserDetails(attrs),
    [UserActionType.IncludeInProgram]: (attrs: AttrsType<typeof IncludeInProgram>) => new IncludeInProgram(attrs),
    [UserActionType.UpdateExecEdEligibilityBundle]: (attrs: AttrsType<typeof UpdateExecEdEligibilityBundle>) =>
        new UpdateExecEdEligibilityBundle(attrs),
    [UserActionType.AllowReapplication]: (attrs: AttrsType<typeof AllowReapplication>) => new AllowReapplication(attrs),
    [UserActionType.RegisterWithCorporateSponsor]: (attrs: AttrsType<typeof RegisterWithCorporateSponsor>) =>
        new RegisterWithCorporateSponsor(attrs),
};

const actionFactories = {
    [UserManagementActionNamespace.ProgramApplication]: applicationActionFactories,
    [UserManagementActionNamespace.AdmissionOffer]: offerActionFactories,
    [UserManagementActionNamespace.ProgramInclusion]: inclusionActionFactories,
    [UserManagementActionNamespace.User]: userActionFactories,
};

export default function instantiateAction({ namespace, actionType, ...attrs }: ActionAttrsWithServerData) {
    const factory = (actionFactories[namespace] as unknown as Record<string, ActionFactory<AttrsType>>)?.[actionType];

    /* As we're developing new actions, we may do the server-side work before we do the client-side work.
    In those cases, we will not be able to instantiate a UserManagementAction on the client even though attributes
    are returned from the server. */
    return factory ? factory(attrs) : undefined;
}
