import { useEffect, useMemo } from 'react';
import { type TuitionPlan } from 'TuitionPlan';
import { type CumulativeScholarship, isFullScholarship } from 'Scholarship';
import { type CohortSectionOffer } from 'CohortSectionOffer';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { type PaymentSituation, paymentSituationsForScholarship } from 'PaymentSituation';
import {
    fullScholarshipPaymentOptions,
    paymentRequiredPaymentOptions,
} from '../../HelperComponents/PaymentSelectHelper';
import { type HandlePaymentOption, type HandlePaymentOptionVal } from '../../HelperComponents/PaymentSelect.types';
import { type FormFields } from './RegisterStudent.types';

export function usePaymentOptions(cohortSectionOffer?: CohortSectionOffer) {
    if (isFullScholarship(cohortSectionOffer?.cumulativeOfferedScholarship)) {
        return fullScholarshipPaymentOptions;
    }

    return paymentRequiredPaymentOptions;
}

export function useTuitionPlans(
    selectablePaymentSituations: PaymentSituation[],
    cumulativeScholarship: CumulativeScholarship | null | undefined,
    availableTuitionPlanIds?: string[],
) {
    // The tuition plans are duplicated across multiple selectablePaymentSituations,
    // so we need to uniq them.
    return useMemo(
        () => [
            ...new Map(
                paymentSituationsForScholarship(selectablePaymentSituations, cumulativeScholarship)
                    .filter(
                        ({ tuitionPlan }) =>
                            !availableTuitionPlanIds || availableTuitionPlanIds.includes(tuitionPlan.id),
                    )
                    .map(({ tuitionPlan }) => [tuitionPlan.id, tuitionPlan]),
            ).values(),
        ],
        [selectablePaymentSituations, cumulativeScholarship, availableTuitionPlanIds],
    );
}

export function useSetDefaultTuitionPlanId(tuitionPlans: TuitionPlan[], tuitionPlanId: string | null) {
    const { setFieldValue } = useFormContext<FormFields>();

    // When a cohort section offer with a full scholarship is selected,
    // only one plan will be available. In that case, we should auto-select it.
    useEffect(() => {
        if (tuitionPlans.length < 2) {
            setFieldValue('tuitionPlanId', tuitionPlans[0]?.id);
        } else if (tuitionPlanId && !tuitionPlans.map(({ id }) => id).includes(tuitionPlanId)) {
            setFieldValue('tuitionPlanId', null);
        }
    }, [tuitionPlans, setFieldValue, tuitionPlanId]);
}

export function useSetDefaultPaymentOption(
    paymentOptions: HandlePaymentOption[],
    paymentOptionValue: HandlePaymentOptionVal | null,
) {
    const { setFieldValue } = useFormContext<FormFields>();

    // When a cohort section offer with a full scholarship is selected,
    // only one payment option will be available. In that case, we should auto-select it.
    useEffect(() => {
        if (paymentOptions.length < 2) {
            setFieldValue('handlePaymentOption', paymentOptions[0]?.value);
        } else if (paymentOptionValue && !paymentOptions.map(({ value }) => value).includes(paymentOptionValue)) {
            setFieldValue('handlePaymentOption', null);
        }
    }, [paymentOptions, setFieldValue, paymentOptionValue]);
}
