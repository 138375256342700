import { type RefObject, useEffect, useState } from 'react';

export const useIntersectionObserver = (
    ref: RefObject<Element> | undefined | null,
    options: IntersectionObserverInit = { rootMargin: '200px' },
) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        if (!ref) return undefined;

        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => observer.disconnect();
    }, [ref, options]);

    return isIntersecting;
};

// Returns an array of booleans indicating whether each ref is intersecting
// Note: that refs must be in a fixed order
export const useIntersectionObservers = (
    refs: RefObject<Element>[],
    options: IntersectionObserverInit = { rootMargin: '200px' },
) => {
    const [visibilityArray, setVisibilityArray] = useState(() => refs.map(() => false));

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            setVisibilityArray(entries.map(entry => entry.isIntersecting));
        }, options);

        refs.forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => observer.disconnect();
    }, [refs, options]);

    return visibilityArray;
};
